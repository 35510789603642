import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Web Development Company`,
    text: "Custom Web Development Services",
    content: "We are a leading web development company delivering website development services tailored to your unique needs. Our expert developers leverage the latest technologies to create visually stunning and user-friendly websites to drive digital success.",
    list: [
      'Custom Web Development Solution',
      'Agile Development Methodology',
      'SEO Optimised Website'
    ]
  };
  const ourData = {
    title: "Clients Take On Our Website Development Services",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Quantify Success With Our <span class='h1-span'>Web Development Agency</span>",
    content: [
      {
        title: "7 Years in Web Development",
        text: "With a dedicated focus on web development for over 7+ years, our agency has offered professional web development services and stayed at the forefront of industry trends and technologies to deliver exceptional solutions.",
      },
      {
        title: "10+ Expert Web Developers",
        text: "We have an experienced team of 10+ best web developers who bring extensive expertise and a passion for innovation to every project, ensuring the highest quality standards in web development.",
      },
      {
        title: "30+ Industries Served",
        text: "Our web development company has catered to the unique needs of over 30+ industries reliant on web technologies, providing tailored solutions that enhance online presence, user experience, and business performance.",
      },
      {
        title: "150+ Successful Web Projects",
        text: "With a proven track record of over 150+ successful web projects, we have demonstrated our ability to deliver dynamic, scalable, and visually appealing websites that drive engagement and results.",
      },
    ],
  };
  const commonContent1 = {
    title: "Empower Business With Our Web Development Company",
    content: [
      {
        title: "Customized Solutions",
        text: "Our web development agency specializes in creating custom web solutions that meet your business needs, from functionality to design, and provide a personalized experience for your users.",
      },
      {
        title: "Interactive Features",
        text: "With our web development services, we incorporate interactive elements into your website to encourage user interaction, gather valuable insights, and provide enhanced functionality for your visitors.",
      },
      {
        title: "Scalable Website",
        text: "With custom web development services, we build flexible and scalable websites that accommodate future growth and changes in your business, saving you time and resources for future updates and redesigns.",
      },
      {
        title: "SEO Integration",
        text: "With our website development services we implement SEO best practices into the development process, optimizing your website's structure, content, and code to improve its ranking in search engine results pages.",
      },
      {
        title: "Cross-Browser Compatibility",
        text: "Our web development company thoroughly tests your website across various browsers to ensure compatibility, providing a uniform experience regardless of your visitors' browser.",
      },
      {
        title: "Responsive Design",
        text: "Our website development company's responsive design approach ensures that your website looks and performs flawlessly on any screen size, maximizing accessibility and user engagement.",
      },
    ],
  };
  const Questions = [
    "What web development services do you offer?",
    "Why should I choose Octet as a Website Development Agency?",
    "How much do your website development services cost?",
    "How long does it take to develop a website?"
  ];
  const faqData = [
    {
      para: [
        `We offer a comprehensive range of web development services to meet diverse business needs, including:`
      ],
      list: [
        `<span><h4 class="inline">Custom Website Development:</h4></span> We specialize in crafting custom websites tailored to your unique requirements, incorporating features and functionality to support your business goals.`,
        `<span><h4 class="inline">E-commerce Development:</h4></span> Our e-commerce development services include creating robust online stores with secure payment gateways, intuitive product catalogs, and seamless checkout processes.`,
        `<span><h4 class="inline">Content Management Systems (CMS):</h4></span> We provide CMS solutions such as WordPress, Drupal, and custom-built systems, enabling you to easily manage and update your website content without technical expertise.`,
        `<span><h4 class="inline">Responsive Web Design:</h4></span> We design and develop websites that adapt seamlessly to various screen sizes and devices, ensuring a consistent user experience across desktops, tablets, and mobile devices.`,
        `<span><h4 class="inline">SEO Optimization:</h4></span> Our website development services incorporate SEO best practices to optimize website structure, content, and code, improving search engine visibility and driving organic traffic.`,
        `<span><h4 class="inline">Web Application Development:</h4></span> We build custom web applications tailored to your requirements and workflows to streamline business processes, automate tasks, and enhance productivity.`,
        `<span><h4 class="inline">Website Maintenance and Support:</h4></span> We offer ongoing maintenance and support services to keep your website secure, up-to-date, and fully functional. We assist with updates, security patches, and technical issues.`,
        `<span><h4 class="inline">Performance Optimization:</h4></span> We prioritize performance optimization to ensure fast load times, smooth functionality, and an optimal user experience. We implement best practices to enhance website performance and usability.`
      ]
    },
    {
      para: [
        `Octet is a leading website development agency that guarantees excellence and innovation. With unparalleled expertise and years of experience, we deliver high-quality web development solutions tailored to your specific needs and objectives.`,
        `Our dedication to customization ensures that your website reflects your brand identity and achieves your goals.`,
        `We prioritize innovation and stay updated with the latest trends and technologies to provide cutting-edge solutions. Client satisfaction is our top priority, as we maintain open communication, deliver projects on time, and provide ongoing support.`,
        `With Octet, you choose a partner dedicated to your success and delivering exceptional results.`
      ],
    },
    {
      para: [
        `The cost of our website development services varies depending on factors such as the project's complexity, the scope of work, and specific client requirements.`,
        `We offer customized pricing packages tailored to each client's needs, ensuring transparency and affordability. <a href="/contact-us/" title="Contact us" target="_blank">Contact us</a> to get a website consultation. `,
      ]
    },
    {
      para: [
        `The timeline for website development depends on various factors, including the project's complexity, the website's size, and the availability of resources.`,
        `Typically, our website development projects can take anywhere from a few weeks to a few months to complete.`,
        `We work closely with our clients to establish realistic timelines and ensure timely delivery of projects without compromising quality.`
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What web development services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We offer a comprehensive range of web development services to meet diverse business needs, including:
          Custom Website Development
          We specialize in crafting custom websites tailored to your unique requirements, incorporating features and functionality to support your business goals.
          E-commerce Development
          Our e-commerce development services include creating robust online stores with secure payment gateways, intuitive product catalogs, and seamless checkout processes.
          Content Management Systems (CMS)
          We provide CMS solutions such as WordPress, Drupal, and custom-built systems, which enable you to manage and update your website content without technical expertise easily.
          Responsive Web Design
          We design and develop websites that adapt seamlessly to various screen sizes and devices, ensuring a consistent user experience across desktops, tablets, and mobile devices.
          SEO Optimization
          Our website development services incorporate SEO best practices to optimize website structure, content, and code, improving search engine visibility and driving organic traffic.
          Web Application Development
          We build custom web applications tailored to your specific requirements and workflows to streamline business processes, automate tasks, and enhance productivity.
          Website Maintenance and Support
          We offer ongoing maintenance and support services to keep your website secure, up-to-date, and fully functional. We provide assistance with updates, security patches, and technical issues.
          Performance Optimization
          We prioritize performance optimization to ensure fast load times, smooth functionality, and an optimal user experience. We implement best practices to enhance website performance and usability."
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet as a Website Development Agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet is a leading website development agency that guarantees excellence and innovation. With unparalleled expertise and years of experience, we deliver high-quality web development solutions tailored to your specific needs and objectives. 
          
          Our dedication to customization ensures that your website reflects your brand identity and achieves your goals. 
          
          We prioritize innovation and stay updated with the latest trends and technologies to provide cutting-edge solutions. Client satisfaction is our top priority, as we maintain open communication, deliver projects on time, and provide ongoing support. 
          
          With Octet, you choose a partner dedicated to your success and delivering exceptional results."
              }
            },{
              "@type": "Question",
              "name": "How much do your website development services cost?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The cost of our website development services varies depending on factors such as the complexity of the project, the scope of work, and specific client requirements. 
          
          We offer customized pricing packages tailored to each client's needs, ensuring transparency and affordability. Contact us to get a website consultation."
              }
            },{
              "@type": "Question",
              "name": "How long does it take to develop a website?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The timeline for website development depends on various factors, including the complexity of the project, the size of the website, and the availability of resources. 
          
          Typically, our website development projects can take anywhere from a few weeks to a few months to complete. 
          
          We work closely with our clients to establish realistic timelines and ensure timely delivery of projects without compromising quality."
              }
            }]
          }                                              
        `}
      </script>
    </Helmet>
    <Layout hire="Web Development Company" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Web Development Company" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Web Development Company | Web Development Services"
    description="Our web development company creates high-quality, responsive & SEO-optimised websites to transform your digital presence. Contact us today!"
  />
);
